@use "../settings/vars" as v;
@use "../settings/mixins" as m;

// kv slider
/* slick-initializedが付与されたら表示 */
#kvSlider {
  display: none;
  &.slick-initialized{
    display: block;
  }
}
@keyframes transfer {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(2.5%);
  }
}
.add-animation {
  animation: transfer 8s linear 0s normal both;
}

// kv gradation
@keyframes bggradient{
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 100%;
	}
	100% {
		background-position: 0% 50%;
	}
}

// hover
.hover-orange {
  transition: 0.3s;
  @include m.hover() {
    transition: 0.3s;
    opacity: 1;
    background-color: v.$orange02;
  }
}
.hover-purple {
  transition: 0.3s;
  @include m.hover() {
    transition: 0.3s;
    opacity: 1;
    background-color: v.$purple02;
  }
}
