@charset "UTF-8";
@font-face {
  font-family: "Copperplate-Gothic-Light-Regular";
  src: url("../fonts/Copperplate Gothic Light Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Copperplate-Gothic-Bold-Regular";
  src: url("../fonts/Copperplate Gothic Bold Regular.ttf") format("truetype");
}
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q, blockquote {
  quotes: none;
}

q:before,
q:after,
blockquote:before,
blockquote:after {
  content: "";
  content: none;
}

a img {
  border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

input,
button,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none;
}

textarea {
  resize: none;
}

input[type=checkbox],
input[type=radio] {
  display: none;
}

input[type=submit],
input[type=button],
label,
button,
select {
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

* {
  box-sizing: border-box;
  word-break: break-all;
}

html {
  -webkit-text-size-adjust: none;
}

body {
  font-family: YakuHanMP, "Noto Serif JP", serif;
  color: #333;
  letter-spacing: 0.04em;
  line-height: 1.5;
  font-feature-settings: "palt";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media screen and (max-width: 599px) {
  body {
    font-size: 14px;
  }
}
body.is-fixed {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
}

a {
  color: #333;
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

ol {
  padding-left: 20px;
  list-style-type: decimal;
}

strong {
  font-weight: bold;
}

sup, sub {
  height: 0;
  line-height: 1;
  vertical-align: baseline;
  _vertical-align: bottom;
  position: relative;
  font-size: 1rem;
}

sup {
  bottom: 1ex;
}

sub {
  top: 0.5ex;
}

/* IE表示用のCSS　*/
body.ie {
  font-family: YakuHanJP_Narrow, Meiryo, sans-serif;
}

.text-block {
  letter-spacing: 0.1em;
  text-align: justify;
  line-height: 2;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 240px;
  height: 50px;
  padding-right: 10px;
  background-color: #0E0A55;
  color: #fff;
  font-weight: 600;
  letter-spacing: 0.08em;
}
.btn._search {
  background-color: #E96D4F;
}
.btn._search::after {
  background-image: url(../images/icon_search.svg);
}
.btn::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  background-image: url(../images/icon-arrow.svg);
  background-position: center 0;
  background-size: contain;
  background-repeat: no-repeat;
}

.layer {
  position: relative;
}
.layer::before {
  content: "";
  opacity: 0.2;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background-color: #554195;
  width: 100%;
  height: 100%;
}

.inner-m,
.inner-l {
  margin: 0 auto;
  padding: 0 30px;
  box-sizing: content-box;
}
@media screen and (max-width: 767px) {
  .inner-m,
.inner-l {
    padding: 0 20px;
  }
}

.inner-m {
  max-width: 1024px;
}

.inner-l {
  max-width: 1200px;
}

a {
  transition: 0.3s;
}
@media (hover: hover) and (pointer: fine) {
  a:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
}

.br-sp {
  display: none;
}
@media screen and (max-width: 599px) {
  .br-sp {
    display: block;
  }
}

.inline-block {
  display: inline-block;
}

.en {
  font-family: "Copperplate-Gothic-Light-Regular", serif;
}

section {
  padding: 120px 0;
}
@media screen and (max-width: 767px) {
  section {
    padding: 60px 0;
  }
}

.section-title {
  display: flex;
  flex-direction: column;
}
.section-title .en {
  color: #554195;
  font-size: 40px;
}
@media screen and (max-width: 599px) {
  .section-title .en {
    font-size: 28px;
  }
}
.section-title .jp {
  font-size: 16px;
  font-weight: 600;
}
@media screen and (max-width: 599px) {
  .section-title .jp {
    font-size: 14px;
  }
}

.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  background-color: #fff;
}

.header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .header-inner {
    height: 80px;
  }
}
@media screen and (max-width: 599px) {
  .header-inner {
    height: 60px;
  }
}

.header-logo {
  width: 300px;
  margin: 5px 0 0 20px;
  text-align: center;
}
@media screen and (max-width: 1200px) {
  .header-logo {
    margin: 5px 0 0 5px;
  }
}
@media screen and (max-width: 599px) {
  .header-logo {
    width: 200px;
  }
}
.header-logo img {
  width: 100%;
  height: 100%;
}
.header-logo span {
  display: inline-block;
  padding-top: 3px;
  font-size: 16px;
  font-weight: 600;
}
@media screen and (max-width: 599px) {
  .header-logo span {
    font-size: 11px;
  }
}

.header-nav {
  display: flex;
}
@media screen and (max-width: 1023px) {
  .header-nav {
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100%;
    overflow: scroll;
    padding: 110px 20px 30px;
    background-color: #F0ECFF;
  }
  .header-nav.active {
    opacity: 1;
    visibility: visible;
    transition: 0.3s;
  }
}
@media screen and (max-width: 599px) {
  .header-nav {
    padding: 90px 20px 30px;
  }
}

.header-nav-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  margin-right: 30px;
}
@media screen and (max-width: 1200px) {
  .header-nav-main {
    margin-right: 15px;
  }
}
@media screen and (max-width: 1023px) {
  .header-nav-main {
    flex-direction: column-reverse;
    align-items: center;
    gap: 0;
    margin-right: 0;
    width: 100%;
  }
  .header-nav-main img {
    width: 20px;
    height: 20px;
  }
}

.header-nav-tel {
  pointer-events: none;
}
@media screen and (max-width: 1023px) {
  .header-nav-tel {
    justify-content: center;
    width: 100%;
    margin: 20px 0;
    padding: 10px 15px;
    background-color: #7056c5;
    text-align: center;
    pointer-events: auto;
  }
}

.header-nav-tel-main {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 6px;
}
@media screen and (max-width: 1023px) {
  .header-nav-tel-main {
    display: inline-flex;
    font-size: 22px;
    color: #fff;
    pointer-events: auto;
  }
}

.header-nav-tel-hours {
  display: none;
}
@media screen and (max-width: 1023px) {
  .header-nav-tel-hours {
    display: block;
    margin-top: 3px;
    color: #fff;
    font-size: 12px;
  }
}

.header-nav-list {
  display: flex;
  gap: 40px;
  font-weight: 600;
}
@media screen and (max-width: 1200px) {
  .header-nav-list {
    gap: 24px;
  }
}
@media screen and (max-width: 1023px) {
  .header-nav-list {
    flex-direction: column;
    gap: 0;
    font-size: 16px;
    width: 100%;
  }
}
@media screen and (max-width: 1023px) {
  .header-nav-list li {
    position: relative;
    border-bottom: 1px solid #d2c4ff;
  }
  .header-nav-list li::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    background-image: url(../images/icon-arrow-navy.svg);
    background-position: center 0;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.header-nav-list li a {
  display: inline-block;
}
@media screen and (max-width: 1023px) {
  .header-nav-list li a {
    display: block;
    padding: 20px 0;
  }
}

.header-btns {
  display: flex;
}
@media screen and (max-width: 1023px) {
  .header-btns {
    width: 100%;
    font-size: 16px;
  }
}

.header-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  width: 120px;
  height: 100px;
  color: #fff;
  font-weight: 600;
}
.header-btn._search {
  background-color: #E96D4F;
}
.header-btn._contact {
  background-color: #0E0A55;
}
@media screen and (max-width: 1023px) {
  .header-btn {
    width: 50%;
    height: 90px;
  }
}

.header-menu-btn {
  display: none;
}
@media screen and (max-width: 1023px) {
  .header-menu-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 80px;
    height: 80px;
    background-color: #0E0A55;
  }
}
@media screen and (max-width: 599px) {
  .header-menu-btn {
    width: 60px;
    height: 60px;
  }
}

.header-menu-btn-inner {
  position: relative;
  width: 60%;
  height: 8px;
}
.header-menu-btn-inner span {
  display: inline-block;
  position: absolute;
  height: 1px;
  background-color: #000;
  background-color: #fff;
  transition: 0.4s;
}
.header-menu-btn-inner span:first-child {
  top: 0;
  width: 100%;
}
.header-menu-btn-inner span:last-child {
  bottom: 0;
  width: 60%;
}
.header-menu-btn-inner.active span {
  transition: 0.4s;
}
.header-menu-btn-inner.active span:first-child {
  transform: translateY(3.5px) rotate(20deg);
}
.header-menu-btn-inner.active span:last-child {
  width: 100%;
  transform: translateY(-3.5px) rotate(-20deg);
}

.footer-main {
  background-color: #fff;
  padding: 100px 0;
}
@media screen and (max-width: 599px) {
  .footer-main {
    padding: 50px 0 30px;
  }
}

.footer-main-inner {
  display: flex;
  justify-content: space-between;
  gap: 60px 30px;
}
@media screen and (max-width: 1023px) {
  .footer-main-inner {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 599px) {
  .footer-main-inner {
    gap: 40px 30px;
  }
}

.footer-logo {
  display: block;
  width: 300px;
  text-align: center;
}
@media screen and (max-width: 599px) {
  .footer-logo {
    width: 200px;
    margin: 0 auto;
  }
}
.footer-logo img {
  width: 100%;
}
.footer-logo span {
  display: block;
  padding-top: 3px;
  font-size: 16px;
  font-weight: 600;
}
@media screen and (max-width: 599px) {
  .footer-logo span {
    font-size: 11px;
  }
}

.footer-info {
  margin-top: 30px;
  font-weight: 500;
}
@media screen and (max-width: 599px) {
  .footer-info {
    margin-top: 20px;
    font-size: 12px;
  }
}

.footer-nav {
  display: flex;
  flex-wrap: wrap;
  gap: 60px;
}
@media screen and (max-width: 599px) {
  .footer-nav {
    gap: 20px;
    max-width: 280px;
    font-size: 12px;
  }
}

.footer-nav-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
@media screen and (max-width: 599px) {
  .footer-nav-list {
    gap: 8px;
    width: 130px;
  }
}
.footer-nav-list li {
  font-weight: 600;
}
.footer-nav-list li.normal {
  font-weight: 400;
}

.footer-copyright {
  display: block;
  width: 100%;
  padding: 18px 20px 16px;
  background-color: #000;
  color: #fff;
  font-size: 10px;
  text-align: center;
}

.kv {
  margin-top: 200px;
}
@media screen and (max-width: 767px) {
  .kv {
    margin-top: 36vw;
  }
}

.kv-copy {
  font-size: 64px;
  font-weight: 600;
  letter-spacing: 0.06em;
  line-height: 1.6;
  white-space: nowrap;
}
@media screen and (max-width: 1023px) {
  .kv-copy {
    font-size: 48px;
  }
}
@media screen and (max-width: 767px) {
  .kv-copy {
    font-size: 6.5vw;
  }
}
.kv-copy > span {
  display: block;
}
.kv-copy .katakana {
  white-space: nowrap;
  letter-spacing: 0.2em;
}
@media screen and (max-width: 767px) {
  .kv-copy .katakana {
    letter-spacing: 0.08em;
  }
}

.kv-copy-main {
  display: inline-block;
  background: linear-gradient(-45deg, #CC208E, #6713D2, #CC208E, #6713D2);
  background: -webkit-linear-gradient(-45deg, #CC208E, #6713D2, #CC208E, #6713D2);
  background-size: 200% 200%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.25em;
  animation: bggradient 10s ease infinite;
}
@media screen and (max-width: 767px) {
  .kv-copy-main {
    font-size: 1.45em;
  }
}

.kv-block {
  position: relative;
  margin-top: 90px;
}
@media screen and (max-width: 1023px) {
  .kv-block {
    margin-top: 8vw;
  }
}

.kv-block-main {
  position: relative;
  width: 65%;
  margin-left: auto;
}
@media screen and (max-width: 1200px) {
  .kv-block-main {
    width: 80%;
  }
}

.kv-layer {
  position: absolute;
  bottom: -40px;
  left: -40px;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: #554195;
}
@media screen and (max-width: 1023px) {
  .kv-layer {
    left: -3.9100684262vw;
    bottom: -3.9100684262vw;
  }
}

.kv-image-list li img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1.05);
}

.kv-pickup {
  position: absolute;
  bottom: 12%;
  left: 0;
  right: 0;
}
@media screen and (max-width: 599px) {
  .kv-pickup {
    bottom: 12%;
    margin-top: 40px;
  }
}
.kv-pickup::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: calc((100% - 1200px) / -2);
  width: calc(100% - 1200px);
  height: 100%;
  background: rgba(240, 236, 255, 0.9);
}
@media screen and (max-width: 1260px) {
  .kv-pickup::before {
    left: 0;
    width: 30px;
  }
}
@media screen and (max-width: 767px) {
  .kv-pickup::before {
    width: 20px;
  }
}

.kv-pickup-main {
  display: block;
  position: relative;
  width: 500px;
  padding: 30px 15px 30px 0;
  background: rgba(240, 236, 255, 0.9);
}
@media screen and (max-width: 1023px) {
  .kv-pickup-main {
    padding: 20px 10px 20px 0;
  }
}
@media screen and (max-width: 599px) {
  .kv-pickup-main {
    width: 90%;
    padding: 12px 10px 14px 0;
    font-size: 11px;
  }
}

.kv-pickup-title {
  margin-bottom: 5px;
  color: #554195;
  font-size: 20px;
}
@media screen and (max-width: 599px) {
  .kv-pickup-title {
    margin-bottom: 1px;
    font-size: 15px;
  }
}

.kv-pickup-link {
  display: flex;
  align-items: baseline;
  gap: 20px;
}
@media screen and (max-width: 599px) {
  .kv-pickup-link {
    gap: 10px;
  }
}
@media screen and (max-width: 599px) {
  .kv-pickup-link .news-catagory {
    font-size: 0.8em;
  }
}

.kv-layer-bottom {
  position: absolute;
  bottom: -80px;
  z-index: -2;
  width: 40%;
  aspect-ratio: 3/1;
  background-color: #F0ECFF;
}
@media screen and (max-width: 1023px) {
  .kv-layer-bottom {
    bottom: -7.8201368524vw;
    aspect-ratio: 1/1;
  }
}

.top-about {
  padding-top: 200px;
}
@media screen and (max-width: 767px) {
  .top-about {
    padding-top: 160px;
  }
}
@media screen and (max-width: 599px) {
  .top-about {
    padding-top: 140px;
  }
}

.top-about-inner {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}
@media screen and (max-width: 1023px) {
  .top-about-inner {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.top-about-copy {
  margin: 40px 0;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 0.1em;
  white-space: nowrap;
}
@media screen and (max-width: 1200px) {
  .top-about-copy {
    font-size: 2.7vw;
  }
}
@media screen and (max-width: 1023px) {
  .top-about-copy {
    font-size: 40px;
  }
}
@media screen and (max-width: 767px) {
  .top-about-copy {
    font-size: 32px;
  }
}
@media screen and (max-width: 599px) {
  .top-about-copy {
    margin: 32px 0 20px;
    font-size: 5vw;
  }
}

.top-about-text {
  max-width: 560px;
}

.strength-triangle {
  flex-shrink: 0;
  position: relative;
  width: 430px;
  height: 400px;
  margin-top: 60px;
}
@media screen and (max-width: 1023px) {
  .strength-triangle {
    margin-top: 20px;
  }
}
@media screen and (max-width: 599px) {
  .strength-triangle {
    margin-top: 0;
    max-width: 330px;
    height: 300px;
  }
}
@media screen and (max-width: 374px) {
  .strength-triangle {
    flex-shrink: 1;
    height: 80.2139037433vw;
    font-size: 3.7533512064vw;
  }
}

.strength-base,
.strength-circle {
  position: absolute;
  border-radius: 50%;
}

.strength-base {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  bottom: 8%;
  left: 50%;
  transform: translateX(-50%);
  width: 320px;
  height: 320px;
  padding-top: 30px;
  background-color: #F0ECFF;
  font-weight: 600;
}
@media screen and (max-width: 599px) {
  .strength-base {
    width: 240px;
    height: 240px;
  }
}
@media screen and (max-width: 374px) {
  .strength-base {
    width: 64.1711229947vw;
    height: 64.1711229947vw;
    padding-top: 8.0213903743vw;
  }
}
.strength-base span {
  line-height: 1.2;
}
.strength-base span:first-child {
  font-size: 24px;
}
@media screen and (max-width: 599px) {
  .strength-base span:first-child {
    font-size: 18px;
  }
}
@media screen and (max-width: 374px) {
  .strength-base span:first-child {
    font-size: 4.8128342246vw;
  }
}
.strength-base span:last-child {
  font-size: 48px;
  letter-spacing: 0.2em;
}
@media screen and (max-width: 599px) {
  .strength-base span:last-child {
    font-size: 36px;
  }
}
@media screen and (max-width: 374px) {
  .strength-base span:last-child {
    font-size: 9.6256684492vw;
  }
}

.strength-circle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 160px;
  height: 160px;
  text-align: center;
}
@media screen and (max-width: 599px) {
  .strength-circle {
    width: 120px;
    height: 120px;
  }
}
@media screen and (max-width: 374px) {
  .strength-circle {
    width: 32.0855614973vw;
    height: 32.0855614973vw;
  }
}
.strength-circle::before {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 150px;
  height: 150px;
  border: 1px solid #fff;
  border-radius: 50%;
  background-color: transparent;
}
@media screen and (max-width: 599px) {
  .strength-circle::before {
    width: 110px;
    height: 110px;
  }
}
@media screen and (max-width: 374px) {
  .strength-circle::before {
    top: 1.0695187166vw;
    left: 1.0695187166vw;
    width: 29.4117647059vw;
    height: 29.4117647059vw;
  }
}
.strength-circle span {
  color: #fff;
  font-weight: 600;
}
.strength-circle .size32 {
  font-size: 32px;
  line-height: 1.3;
}
@media screen and (max-width: 599px) {
  .strength-circle .size32 {
    font-size: 28px;
  }
}
@media screen and (max-width: 374px) {
  .strength-circle .size32 {
    font-size: 7.486631016vw;
  }
}
.strength-circle .size24 {
  font-size: 24px;
  line-height: 1.3;
}
@media screen and (max-width: 599px) {
  .strength-circle .size24 {
    font-size: 18px;
  }
}
@media screen and (max-width: 374px) {
  .strength-circle .size24 {
    font-size: 4.8128342246vw;
  }
}
.strength-circle._first {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #33A00A;
}
.strength-circle._second {
  right: 0;
  bottom: 0;
  background-color: #E80303;
}
.strength-circle._third {
  left: 0;
  bottom: 0;
  background-color: #FF4403;
}
.strength-circle._third .size24 {
  letter-spacing: -0.15em;
}

.top-service {
  background-color: #F0F6F7;
}

.top-service-list {
  display: flex;
  flex-direction: column;
  gap: 60px;
  margin-top: 60px;
}
@media screen and (max-width: 767px) {
  .top-service-list {
    margin-top: 30px;
  }
}

.top-service-item {
  display: flex;
  align-items: center;
  gap: 30px 50px;
}
@media screen and (max-width: 1023px) {
  .top-service-item {
    flex-direction: column;
  }
}
.top-service-item:nth-child(even) {
  flex-direction: row-reverse;
}
@media screen and (max-width: 1023px) {
  .top-service-item:nth-child(even) {
    flex-direction: column;
  }
}

.top-service-image {
  width: 50%;
}
@media screen and (max-width: 1023px) {
  .top-service-image {
    width: 100%;
  }
}
@media screen and (max-width: 1023px) {
  .top-service-image img {
    width: 100%;
    aspect-ratio: 5/2;
    object-fit: cover;
  }
}

.top-service-content {
  flex: 1;
}

.top-service-title {
  display: flex;
  flex-direction: column;
}
.top-service-title .jp {
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 599px) {
  .top-service-title .jp {
    font-size: 24px;
  }
}

.top-service-text {
  margin: 30px 0 40px;
}
@media screen and (max-width: 767px) {
  .top-service-text {
    margin: 12px 0 24px;
  }
}

.top-search {
  padding-bottom: 0;
}
@media screen and (max-width: 1023px) {
  .top-search .inner-l {
    padding: 0;
  }
}

.top-search-block {
  position: relative;
  z-index: 1;
  padding: 100px 0 70px;
  background-image: url(../images/bg_search.jpg);
  background-position: center 0;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  text-align: center;
}
@media screen and (max-width: 599px) {
  .top-search-block {
    padding: 60px 0 40px;
  }
}
.top-search-block::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: rgba(14, 10, 85, 0.6);
}
.top-search-block .btn {
  margin: 0 auto;
}

.top-search-title {
  font-size: 40px;
}
@media screen and (max-width: 599px) {
  .top-search-title {
    font-size: 30px;
  }
}

.top-search-text {
  margin: 24px 0 36px;
  line-height: 2;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 599px) {
  .top-search-text {
    margin: 20px 0 30px;
  }
}
.top-search-text span {
  display: block;
}

.top-news {
  padding-bottom: 0;
}

.top-news-inner {
  display: flex;
  gap: 40px 120px;
}
@media screen and (max-width: 1023px) {
  .top-news-inner {
    flex-direction: column;
  }
}
@media screen and (max-width: 767px) {
  .top-news-inner {
    gap: 30px;
  }
}

.top-news-left {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
@media screen and (max-width: 1023px) {
  .top-news-left .btn {
    display: none;
  }
}

.top-news-right .btn {
  display: none;
}
@media screen and (max-width: 1023px) {
  .top-news-right .btn {
    display: flex;
    margin-top: 40px;
  }
}
@media screen and (max-width: 599px) {
  .top-news-right .btn {
    margin-top: 30px;
  }
}

.news-list {
  flex: 1;
}

.news-item {
  border-top: 1px solid #D4D4D4;
}
.news-item:last-child {
  border-bottom: 1px solid #D4D4D4;
}
.news-item a {
  display: flex;
  align-items: baseline;
  gap: 10px 20px;
  padding: 20px 0;
}
@media screen and (max-width: 767px) {
  .news-item a {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 599px) {
  .news-item a {
    gap: 8px 12px;
    padding: 14px 0;
  }
}

.news-date {
  flex-shrink: 0;
}
@media screen and (max-width: 599px) {
  .news-date {
    font-size: 0.8em;
  }
}

.news-catagory {
  flex-shrink: 0;
  padding: 4px 10px;
  background-color: #333;
  color: #fff;
  font-size: 0.9em;
}
@media screen and (max-width: 599px) {
  .news-catagory {
    padding: 1px 5px;
    font-size: 0.65em;
  }
}

.news-text {
  letter-spacing: 0.08em;
}
@media screen and (max-width: 767px) {
  .news-text {
    width: 100%;
  }
}

.top-info {
  padding-bottom: 0;
}

.top-info-inner {
  display: flex;
  align-items: center;
  gap: 24px 50px;
  position: relative;
}
.top-info-inner._reverse {
  flex-direction: row-reverse;
}
@media screen and (max-width: 1023px) {
  .top-info-inner, .top-info-inner._reverse {
    flex-direction: column-reverse;
  }
}

.top-info-content {
  flex: 1;
}

.top-info-text {
  margin: 20px 0 30px;
}

.top-info-image {
  width: 700px;
}
@media screen and (max-width: 1023px) {
  .top-info-image {
    width: 100%;
  }
  .top-info-image img {
    width: 100%;
    aspect-ratio: 5/2;
    object-fit: cover;
  }
}

.top-contact {
  padding-bottom: 0;
  display: flex;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .top-contact {
    flex-direction: column;
  }
}

.top-contact-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 200px;
  padding: 0 20px;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .top-contact-item {
    width: 100%;
  }
}
@media screen and (max-width: 599px) {
  .top-contact-item {
    height: 150px;
  }
}

.top-contact-tel {
  background-color: #7056c5;
  pointer-events: none;
}
@media screen and (max-width: 599px) {
  .top-contact-tel {
    pointer-events: auto;
  }
}
.top-contact-tel .top-contact-title {
  margin-top: 20px;
}
@media screen and (max-width: 599px) {
  .top-contact-tel .top-contact-title {
    margin-top: 15px;
  }
}

.top-contact-mail {
  padding-top: 24px;
  background-color: #0E0A55;
}
@media screen and (max-width: 599px) {
  .top-contact-mail {
    padding-top: 0;
  }
}
.top-contact-mail .top-contact-title {
  margin-top: 14px;
}

.top-contact-title {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 599px) {
  .top-contact-title {
    font-size: 16px;
  }
}
.top-contact-title span {
  display: inline-block;
}

.top-contact-tel-num {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 24px;
}
.top-contact-tel-num img {
  width: 24px;
  height: 24px;
}

.top-contact-hours {
  font-size: 12px;
}

.top-contact-mail-icon {
  width: 36px;
  margin: 0 auto;
}

/* slick-initializedが付与されたら表示 */
#kvSlider {
  display: none;
}
#kvSlider.slick-initialized {
  display: block;
}

@keyframes transfer {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(2.5%);
  }
}
.add-animation {
  animation: transfer 8s linear 0s normal both;
}

@keyframes bggradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.hover-orange {
  transition: 0.3s;
}
@media (hover: hover) and (pointer: fine) {
  .hover-orange:hover {
    transition: 0.3s;
    opacity: 1;
    background-color: #FF4403;
  }
}

.hover-purple {
  transition: 0.3s;
}
@media (hover: hover) and (pointer: fine) {
  .hover-purple:hover {
    transition: 0.3s;
    opacity: 1;
    background-color: #554195;
  }
}