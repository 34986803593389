@use "../settings/vars" as v;
@use "../settings/mixins" as m;

// inner
.inner-m,
.inner-l {
	margin: 0 auto;
	padding: 0 30px;
	box-sizing: content-box;
	@include m.mq(max767) {
		padding: 0 20px;
	}
}
.inner-m {
	max-width: 1024px;
}
.inner-l {
	max-width: 1200px;
}

a {
	transition: 0.3s;
	@include m.hover() {
		opacity: 0.7;
		transition: 0.3s;
	}
}

//br
.br-sp {
	display: none;
	@include m.mq(max599) {
		display: block;
	}
}

// inline, block
.inline-block {
	display: inline-block;
}

// font
.en {
	@include m.copperplate-light;
}

// section
section {
	padding: 120px 0;
	@include m.mq(max767) {
		padding: 60px 0;
	}
}
.section-title {
	display: flex;
	flex-direction: column;
	.en {
		color: v.$purple02;
		font-size: 40px;
		@include m.mq(max599) {
			font-size: 28px;
		}
	}
	.jp {
		font-size: 16px;
		font-weight: 600;
		@include m.mq(max599) {
			font-size: 14px;
		}
	}
}

// header
.header {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 100;
	width: 100%;
	background-color: v.$white;
}
.header-inner {
	display: flex;
	justify-content: space-between;
	align-items: center;
	@include m.mq(max767) {
		height: 80px;
	}
	@include m.mq(max599) {
		height: 60px;
	}
}
.header-logo {
	width: 300px;
	margin: 5px 0 0 20px;
	text-align: center;
	@include m.mq(max1200) {
		margin: 5px 0 0 5px;
	}
	@include m.mq(max599) {
		width: 200px;
	}
	img {
		width: 100%;
		height: 100%;
	}
	span {
		display: inline-block;
		padding-top: 3px;
		font-size: 16px;
		font-weight: 600;
		@include m.mq(max599) {
			font-size: 11px;
		}
	}
}
.header-nav {
	display: flex;
	@include m.mq(max1023) {
		opacity: 0;
		visibility: hidden;
		transition: 0.3s;
		flex-direction: column;
		align-items: center;
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		height: 100%;
		overflow: scroll;
		padding: 110px 20px 30px;
		background-color: v.$purple03;
		&.active {
			opacity: 1;
			visibility: visible;
			transition: 0.3s;
		}
	}
	@include m.mq(max599) {
		padding: 90px 20px 30px;
	}
}
.header-nav-main {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 10px;
	margin-right: 30px;
	@include m.mq(max1200) {
		margin-right: 15px;
	}
	@include m.mq(max1023) {
		flex-direction: column-reverse;
		align-items: center;
		gap: 0;
		margin-right: 0;
		width: 100%;
		img {
			width: 20px;
			height: 20px;
		}
	}
}
.header-nav-tel {
	pointer-events: none;
	@include m.mq(max1023) {
		justify-content: center;
		width: 100%;
		margin: 20px 0;
    padding: 10px 15px;
		background-color: v.$purple05;
		text-align: center;
		pointer-events: auto;
	}
}
.header-nav-tel-main {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 6px;
	@include m.mq(max1023) {
		display: inline-flex;
		font-size: 22px;
		color: v.$white;
		pointer-events: auto;
	}
}
.header-nav-tel-hours {
	display: none;
	@include m.mq(max1023) {
		display: block;
		margin-top: 3px;
		color: v.$white;
		font-size: 12px;
	}
}
.header-nav-list {
	display: flex;
	gap: 40px;
	font-weight: 600;
	@include m.mq(max1200) {
		gap: 24px;
	}
	@include m.mq(max1023) {
		flex-direction: column;
		gap: 0;
		font-size: 16px;
		width: 100%;
	}
	li {
		@include m.mq(max1023) {
			position: relative;
			border-bottom: 1px solid v.$purple06;
			&::after {
				content: '';
				position: absolute;
				top: 50%;
				right: 10px;
				transform: translateY(-50%);
				width: 12px;
				height: 12px;
				background-image: url(../images/icon-arrow-navy.svg);
				background-position: center 0;
				background-size: contain;
				background-repeat: no-repeat;
			}
		}
		a {
			display: inline-block;
			@include m.mq(max1023) {
				display: block;
				padding: 20px 0;
			}
		}
	}
}
.header-btns {
	display: flex;
	@include m.mq(max1023) {
		width: 100%;
		font-size: 16px;
	}
}
.header-btn {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 6px;
	width: 120px;
	height: 100px;
	color: v.$white;
	font-weight: 600;
	&._search {
		background-color: v.$orange01;
	}
	&._contact {
		background-color: v.$purple01;
	}
	@include m.mq(max1023) {
		width: 50%;
		height: 90px;
	}
}
.header-menu-btn {
	display: none;
	@include m.mq(max1023) {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		width: 80px;
		height: 80px;
		background-color: v.$purple01;
	}
	@include m.mq(max599) {
		width: 60px;
		height: 60px;
	}
}
.header-menu-btn-inner {
	position: relative;
	width: 60%;
	height: 8px;
	span {
		display: inline-block;
		position: absolute;
		height: 1px;
		background-color: v.$black02;
		background-color: v.$white;
		transition: 0.4s;
		&:first-child {
			top: 0;
			width: 100%;
		}
		&:last-child {
			bottom: 0;
			width: 60%;
		}
	}
	&.active {
		span {
			transition: 0.4s;
			&:first-child {
				transform: translateY(3.5px) rotate(20deg);
			}
			&:last-child {
				width: 100%;
				transform: translateY(-3.5px) rotate(-20deg);
			}
		}
	}
}

// footer
.footer-main {
	background-color: v.$white;
	padding: 100px 0;
	@include m.mq(max599) {
		padding: 50px 0 30px;
	}
}
.footer-main-inner {
	display: flex;
	justify-content: space-between;
	gap: 60px 30px;
	@include m.mq(max1023) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	@include m.mq(max599) {
		gap: 40px 30px;
	}
}
.footer-logo {
	display: block;
	width: 300px;
	text-align: center;
	@include m.mq(max599) {
		width: 200px;
		margin: 0 auto;
	}
	img {
		width: 100%;
	}
	span {
		display: block;
		padding-top: 3px;
		font-size: 16px;
		font-weight: 600;
		@include m.mq(max599) {
			font-size: 11px;
		}
	}
}
.footer-info {
	margin-top: 30px;
	font-weight: 500;
	@include m.mq(max599) {
		margin-top: 20px;
		font-size: 12px;
	}
}
.footer-nav {
	display: flex;
	flex-wrap: wrap;
	gap: 60px;
	@include m.mq(max599) {
		gap: 20px;
		max-width: 280px;
		font-size: 12px;
	}
}
.footer-nav-list {
	display: flex;
	flex-direction: column;
	gap: 16px;
	@include m.mq(max599) {
		gap: 8px;
		width: 130px;
	}
	li {
		font-weight: 600;
		&.normal {
			font-weight: 400;
		}
	}
}
.footer-copyright {
	display: block;
	width: 100%;
	padding: 18px 20px 16px;
	background-color: v.$black02;
	color: v.$white;
	font-size: 10px;
	text-align: center;
}
