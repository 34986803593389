@use "../settings/vars" as v;
@use "../settings/mixins" as m;

// text
.text-block {
	letter-spacing: 0.1em;
  text-align: justify;
  line-height: 2;
}

// btn
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 240px;
  height: 50px;
  padding-right: 10px;
  background-color: v.$purple04;
  color: v.$white;
  font-weight: 600;
  letter-spacing: 0.08em;
  &._search {
    background-color: v.$orange01;
    &::after {
      background-image: url(../images/icon_search.svg);
    }
  }
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 14px;
    height: 14px;
    background-image: url(../images/icon-arrow.svg);
    background-position: center 0;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

// layer
.layer {
  position: relative;
  &::before {
    content: '';
    opacity: 0.2;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    background-color: v.$purple02;
    width: 100%;
    height: 100%;
  }
}
