@use "../settings/vars" as v;
@use "../settings/mixins" as m;

.kv {
  margin-top: 200px;
  @include m.mq(max767) {
    margin-top: 36vw;
  }
}
.kv-copy {
  font-size: 64px;
  font-weight: 600;
  letter-spacing: 0.06em;
  line-height: 1.6;
  white-space: nowrap;
  @include m.mq(max1023) {
    font-size: 48px;
  }
  @include m.mq(max767) {
    font-size: 6.5vw;
  }
  > span {
    display: block;
  }
  .katakana {
    white-space: nowrap;
    letter-spacing: 0.2em;
    @include m.mq(max767) {
      letter-spacing: 0.08em;
    }
  }
}
.kv-copy-main {
  display: inline-block;
  background: linear-gradient(-45deg, #CC208E, #6713D2, #CC208E, #6713D2);
  background: -webkit-linear-gradient(-45deg, #CC208E, #6713D2, #CC208E, #6713D2);
  background-size: 200% 200%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.25em;
  animation: bggradient 10s ease infinite;
  @include m.mq(max767) {
    font-size: 1.45em;
  }
}
.kv-block {
  position: relative;
  margin-top: 90px;
  @include m.mq(max1023) {
    margin-top: 8vw;
  }
}
.kv-block-main {
  position: relative;
  width: 65%;
  margin-left: auto;
  @include m.mq(max1200) {
    width: 80%;
  }
}
.kv-layer {
  position: absolute;
  bottom: -40px;
  left: -40px;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: v.$purple02;
  @include m.mq(max1023) {
    left: calc((40 / 1023)*-100vw);
    bottom: calc((40 / 1023)*-100vw);
  }
}
.kv-image-list {
  li {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: scale(1.05);
    }
  }
}
.kv-pickup {
  position: absolute;
  bottom: 12%;
  left: 0;
  right: 0;
  @include m.mq(max599) {
    bottom: 12%;
    margin-top: 40px;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc((100% - 1200px)/-2);
    width: calc((100% - 1200px));
    height: 100%;
    background: rgba(240, 236, 255, 0.9);
    @include m.mq(max1260) {
      left: 0;
      width: 30px;
    }
    @include m.mq(max767) {
      width: 20px;
    }
  }
}
.kv-pickup-main {
  display: block;
  position: relative;
  width: 500px;
  padding: 30px 15px 30px 0;
  background: rgba(240, 236, 255, 0.9);
  @include m.mq(max1023) {
    padding: 20px 10px 20px 0;
  }
  @include m.mq(max599) {
    width: 90%;
    padding: 12px 10px 14px 0;
    font-size: 11px;
  }
}
.kv-pickup-title {
  margin-bottom: 5px;
  color: v.$purple02;
  font-size: 20px;
  @include m.mq(max599) {
    margin-bottom: 1px;
    font-size: 15px;
  }
}
.kv-pickup-link {
  display: flex;
  align-items: baseline;
  gap: 20px;
  @include m.mq(max599) {
    gap: 10px;
  }
  .news-catagory {
    @include m.mq(max599) {
      font-size: 0.8em;
    }
  }
}
.kv-layer-bottom {
  position: absolute;
  bottom: -80px;
  z-index: -2;
  width: 40%;
  aspect-ratio: 3 / 1;
  background-color: v.$purple03;
  @include m.mq(max1023) {
    bottom: calc((80 / 1023)*-100vw);
    aspect-ratio: 1 / 1;
  }
}

// top-about
.top-about {
  padding-top: 200px;
  @include m.mq(max767) {
    padding-top: 160px;
  }
  @include m.mq(max599) {
    padding-top: 140px;
  }
}
.top-about-inner {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  @include m.mq(max1023) {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.top-about-content {
}
.top-about-copy {
  margin: 40px 0;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 0.1em;
  white-space: nowrap;
  @include m.mq(max1200) {
    font-size: 2.7vw;
  }
  @include m.mq(max1023) {
    font-size: 40px;
  }
  @include m.mq(max767) {
    font-size: 32px;
  }
  @include m.mq(max599) {
    margin: 32px 0 20px;
    font-size: 5vw;
  }
}
.top-about-text {
  max-width: 560px;
}
.strength-triangle {
  flex-shrink: 0;
  position: relative;
  width: 430px;
  height: 400px;
  margin-top: 60px;
  @include m.mq(max1023) {
    margin-top: 20px;
  }
  @include m.mq(max599) {
    margin-top: 0;
    max-width: 330px;
    height: 300px;
  }
  @include m.mq(max374) {
    flex-shrink: 1;
    height: calc((300/374)*100vw);
    font-size: calc((14/373)*100vw);
  }
}
.strength-base,
.strength-circle {
  position: absolute;
  border-radius: 50%;
}
.strength-base {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  bottom: 8%;
  left: 50%;
  transform: translateX(-50%);
  width: 320px;
  height: 320px;
  padding-top: 30px;
  background-color: v.$purple03;
  font-weight: 600;
  @include m.mq(max599) {
    width: 240px;
    height: 240px;
  }
  @include m.mq(max374) {
    width: calc((240/374)*100vw);
    height: calc((240/374)*100vw);
    padding-top: calc((30/374)*100vw);
  }
  span {
    line-height: 1.2;
    &:first-child {
      font-size: 24px;
      @include m.mq(max599) {
        font-size: 18px;
      }
      @include m.mq(max374) {
        font-size: calc((18/374)*100vw);
      }
    }
    &:last-child {
      font-size: 48px;
      letter-spacing: 0.2em;
      @include m.mq(max599) {
        font-size: 36px;
      }
      @include m.mq(max374) {
        font-size: calc((36/374)*100vw);
      }
    }
  }
}
.strength-circle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 160px;
  height: 160px;
  text-align: center;
  @include m.mq(max599) {
    width: 120px;
    height: 120px;
  }
  @include m.mq(max374) {
    width: calc((120/374)*100vw);
    height: calc((120/374)*100vw);
  }
  &::before {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 150px;
    height: 150px;
    border: 1px solid v.$white;
    border-radius: 50%;
    background-color: transparent;
    @include m.mq(max599) {
      width: 110px;
      height: 110px;
    }
    @include m.mq(max374) {
      top: calc((4/374)*100vw);
      left: calc((4/374)*100vw);
      width: calc((110/374)*100vw);
      height: calc((110/374)*100vw);
    }
  }
  span {
    color: v.$white;
    font-weight: 600;
  }
  .size32 {
    font-size: 32px;
    line-height: 1.3;
    @include m.mq(max599) {
      font-size: 28px;
    }
    @include m.mq(max374) {
      font-size: calc((28/374)*100vw);
    }
  }
  .size24 {
    font-size: 24px;
    line-height: 1.3;
    @include m.mq(max599) {
      font-size: 18px;
    }
    @include m.mq(max374) {
      font-size: calc((18/374)*100vw);
    }
  }
  &._first {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: v.$green;
  }
  &._second {
    right: 0;
    bottom: 0;
    background-color: v.$red;

  }
  &._third {
    left: 0;
    bottom: 0;
    background-color: v.$orange02;
    .size24 {
      letter-spacing: -0.15em;
    }
  }
}

// service
.top-service {
  background-color: v.$gray02;
}
.top-service-list {
  display: flex;
  flex-direction: column;
  gap: 60px;
  margin-top: 60px;
  @include m.mq(max767) {
    margin-top: 30px;
  }
}
.top-service-item {
  display: flex;
  align-items: center;
  gap: 30px 50px;
  @include m.mq(max1023) {
    flex-direction: column;
  }
  &:nth-child(even) {
    flex-direction: row-reverse;
    @include m.mq(max1023) {
      flex-direction: column;
    }
  }
}
.top-service-image {
  width: 50%;
  @include m.mq(max1023) {
    width: 100%;
  }
  img {
    @include m.mq(max1023) {
      width: 100%;
      aspect-ratio: 5 / 2;
      object-fit: cover;
    }
  }
}
.top-service-content {
  flex: 1;
}
.top-service-title {
  display: flex;
  flex-direction: column;
  .jp {
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0.1em;
    @include m.mq(max599) {
      font-size: 24px;
    }
  }
}
.top-service-text {
  margin: 30px 0 40px;
  @include m.mq(max767) {
    margin: 12px 0 24px;
  }
}

// top-search
.top-search {
  padding-bottom: 0;
  .inner-l {
    @include m.mq(max1023) {
      padding: 0;
    }
  }
}
.top-search-block {
  position: relative;
  z-index: 1;
  padding: 100px 0 70px;
  background-image: url(../images/bg_search.jpg);
  background-position: center 0;
  background-size: cover;
  background-repeat: no-repeat;
  color: v.$white;
  text-align: center;
  @include m.mq(max599) {
    padding: 60px 0 40px;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: rgba(14, 10, 85, 0.6);
  }
  .btn {
    margin: 0 auto;
  }
}
.top-search-title {
  font-size: 40px;
  @include m.mq(max599) {
    font-size: 30px;
  }
}
.top-search-text {
  margin: 24px 0 36px;
  line-height: 2;
  letter-spacing: 0.1em;
  @include m.mq(max599) {
    margin: 20px 0 30px;
  }
  span {
    display: block;
  }
}

// top-news
.top-news {
  padding-bottom: 0;
}
.top-news-inner {
  display: flex;
  gap: 40px 120px;
  @include m.mq(max1023) {
    flex-direction: column;
  }
  @include m.mq(max767) {
    gap: 30px;
  }
}
.top-news-left {
  display: flex;
  flex-direction: column;
  gap: 40px;
  .btn {
    @include m.mq(max1023) {
      display: none;
    }
  }
}
.top-news-right {
  .btn {
    display: none;
    @include m.mq(max1023) {
      display: flex;
      margin-top: 40px;
    }
    @include m.mq(max599) {
      margin-top: 30px;
    }
  }
}
.news-list {
  flex: 1;
}
.news-item {
  border-top: 1px solid v.$gray03;
  &:last-child {
    border-bottom: 1px solid v.$gray03;
  }
  a {
    display: flex;
    align-items: baseline;
    gap: 10px 20px;
    padding: 20px 0;
    @include m.mq(max767) {
      flex-wrap: wrap;
    }
    @include m.mq(max599) {
      gap: 8px 12px;
      padding: 14px 0;
    }
  }
}
.news-date {
  flex-shrink: 0;
  @include m.mq(max599) {
    font-size: 0.8em;
  }
}
.news-catagory {
  flex-shrink: 0;
  padding: 4px 10px;
  background-color: v.$black01;
  color: v.$white;
  font-size: 0.9em;
  @include m.mq(max599) {
    padding: 1px 5px;
    font-size: 0.65em;
  }
}
.news-text {
  letter-spacing: 0.08em;
  @include m.mq(max767) {
    width: 100%;
  }
}

// top-info
.top-info {
  padding-bottom: 0;
}
.top-info-inner {
  display: flex;
  align-items: center;
  gap: 24px 50px;
  position: relative;
  &._reverse {
    flex-direction: row-reverse;
  }
  @include m.mq(max1023) {
    &, &._reverse {
      flex-direction: column-reverse;
    }
  }
}
.top-info-content {
  flex: 1;
}
.top-info-text {
  margin: 20px 0 30px;
}
.top-info-image {
  width: 700px;
  @include m.mq(max1023) {
    width: 100%;
    img {
      width: 100%;
      aspect-ratio: 5 / 2;
      object-fit: cover;
    }
  }
}

// top-contact
.top-contact {
  padding-bottom: 0;
  display: flex;
  text-align: center;
  @include m.mq(max767) {
    flex-direction: column;
  }
}
.top-contact-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 200px;
  padding: 0 20px;
  color: v.$white;
  @include m.mq(max767) {
    width: 100%;
  }
  @include m.mq(max599) {
    height: 150px;
  }
}
.top-contact-tel {
  background-color: v.$purple05;
  pointer-events: none;
  @include m.mq(max599) {
    pointer-events: auto;
  }
  .top-contact-title {
    margin-top: 20px;
    @include m.mq(max599) {
      margin-top: 15px;
    }
  }
}
.top-contact-mail {
  padding-top: 24px;
  background-color: v.$purple01;
  @include m.mq(max599) {
    padding-top: 0;
  }
  .top-contact-title {
    margin-top: 14px;
  }
}
.top-contact-title {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.08em;
  @include m.mq(max599) {
    font-size: 16px;
  }
  span {
    display: inline-block;
  }
}
.top-contact-tel-num {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 24px;
  img {
    width: 24px;
    height: 24px;
  }
}
.top-contact-hours {
  font-size: 12px;
}
.top-contact-mail-icon {
  width: 36px;
  margin: 0 auto;
}
