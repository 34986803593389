@use "../settings/vars" as v;
@use "../settings/mixins" as m;

* {
  box-sizing: border-box;
  word-break: break-all;
}
html {
	-webkit-text-size-adjust: none;
}
body {
  font-family: YakuHanMP, "Noto Serif JP", serif;
  color: v.$black01;
  letter-spacing: 0.04em;
  line-height: 1.5;
  font-feature-settings: "palt";
	-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include m.mq(max599) {
    font-size: 14px;
  }
  &.is-fixed {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
  }
}
a {
	color: v.$black01;
	text-decoration: none;
}
img {
  display: block;
	max-width: 100%;
  height: auto;
  vertical-align: bottom;
}
ol {
  padding-left: 20px;
  list-style-type: decimal;
}
strong {
  font-weight: bold;
}
sup, sub {
  height: 0;
  line-height: 1;
  vertical-align: baseline;
  _vertical-align: bottom;
  position: relative;
  font-size: 1rem;
}
sup {
  bottom: 1ex;
}
sub {
  top: .5ex;
}

/* IE表示用のCSS　*/
body.ie {
  font-family: YakuHanJP_Narrow, Meiryo, sans-serif;
}

// フォント

// 色

// レイアウト
